<template>
  <slot />
  <TransitionGroup name="fade" tag="div" class="app-notifications">
    <RNotification
      v-for="d in notifications"
      :key="d.id"
      :variant="d.variant"
      light
      class="notification-ext"
    >
      {{ getVariantIcon(d.variant) }} {{ d.message }}
    </RNotification>
  </TransitionGroup>
</template>

<script lang="ts" setup>
import { RNotification } from '@shveitsar/bulmavue'
import { provide, shallowRef } from 'vue'
import { delay } from '@shveitsar/toolbox'
import {
  getVariantIcon,
  notifyKey,
  type NotificationData
} from './Notifications'

const notifications = shallowRef<NotificationData[]>([])

provide(notifyKey, async (message, variant) => {
  const data = {
    message,
    variant,
    id: ++lastNotificationId
  }
  notifications.value = [...notifications.value, data]
  await delay(5000)
  notifications.value = notifications.value.filter((i) => i !== data)
})
</script>

<script lang="ts">
let lastNotificationId = 0
</script>

<style lang="scss">
.app-notifications {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1em;
}
.notification-ext {
  z-index: 99999;
}
</style>
