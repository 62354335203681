import { createRouter, createWebHistory } from 'vue-router'

import routes from '~pages'

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...routes,
    {
      path: '/agency/:restPathMatch(.*)*',
      redirect: (to) =>
        to.path
          .replace(/\bagency\b/, 'agencies')
          .replace(/\bapartment\b/, 'apartments')
          .replace(/\bapartments\/tl\:(.+)\:\d+\b/, 'apartments/$1')
          .replace(/\/edit\b/, '')
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'agencies' }
    }
  ]
})
