import '@shveitsar/bulmavue/style.css'
import 'bulma/css/bulma.min.css'

import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'

const app = createApp(App).use(router)

app.mount('#app')

Reflect.set(window, '$misc', {
  router,
  app
})
