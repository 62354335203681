<template>
  <slot />
  <transition name="fade">
    <div
      v-if="loadingsCount > 0"
      class="loader-container is-overlay is-flex is-justify-content-center is-align-items-center"
    >
      <div class="loader is-size-1"></div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.loader-container {
  position: fixed;
  background-color: rgba($color: white, $alpha: 0.4);
}
</style>

<script lang="ts" setup>
import { provide, ref } from 'vue'
import { useNotifier } from './Notifications'

const notify = useNotifier()

const loadingsCount = ref(0)

provide(
  'async-wrapper',
  (fn: (...args: any[]) => Promise<any>) =>
    async (...args: any[]) => {
      try {
        loadingsCount.value += 1
        return await fn(...args)
      } catch (e: any) {
        console.error(e)
        notify(e.message, 'danger')
      } finally {
        loadingsCount.value -= 1
      }
    }
)
</script>
