import type { IVariant } from '@shveitsar/bulmavue'
import { inject, type InjectionKey } from 'vue'

export function useNotifier() {
  return inject(notifyKey, fallbackNotifier)
}

export interface NotificationData {
  id: number
  message: string
  variant?: IVariant
}

export const notifyKey: InjectionKey<
  (message: string, variant?: IVariant) => void
> = Symbol()

export function getVariantIcon(variant?: IVariant): string {
  const notifyIcon = {
    warning: '⚠️',
    danger: '🚫',
    success: '✔️',
    info: 'ℹ️',
    link: '🔗'
  } as any
  return notifyIcon[variant!] ?? ''
}

function fallbackNotifier(message: string, variant?: IVariant) {
  alert(`${getVariantIcon(variant)} ${message}`)
}
