const __pages_import_0__ = () => import("/src/pages/agencies/index.vue");
const __pages_import_1__ = () => import("/src/pages/[agencyOrApartmentId].vue");
const __pages_import_2__ = () => import("/src/pages/agencies/[agencyId].vue");
const __pages_import_3__ = () => import("/src/pages/agencies/[agencyId]/index.vue");
const __pages_import_4__ = () => import("/src/pages/agencies/[agencyId]/apartments/index.vue");
const __pages_import_5__ = () => import("/src/pages/agencies/[agencyId]/apartments/[apartmentId].vue");
const __pages_import_6__ = () => import("/src/pages/agencies/[agencyId]/employees/index.vue");
const __pages_import_7__ = () => import("/src/pages/agencies/[agencyId]/employees/[employeeId].vue");
const __pages_import_8__ = () => import("/src/pages/agencies/[agencyId]/payouts/tinkoff-sd.vue");
const __pages_import_9__ = () => import("/src/pages/agencies/[agencyId]/tl/wizard.vue");

const routes = [{"name":"agencies","path":"/agencies","component":__pages_import_0__,"props":true},{"name":"agencyOrApartmentId","path":"/:agencyOrApartmentId","component":__pages_import_1__,"props":true},{"path":"/agencies/:agencyId","component":__pages_import_2__,"children":[{"name":"agencies-agencyId","path":"","component":__pages_import_3__,"props":true},{"name":"agencies-agencyId-apartments","path":"apartments","component":__pages_import_4__,"props":true},{"name":"agencies-agencyId-apartments-apartmentId","path":"apartments/:apartmentId","component":__pages_import_5__,"props":true},{"name":"agencies-agencyId-employees","path":"employees","component":__pages_import_6__,"props":true},{"name":"agencies-agencyId-employees-employeeId","path":"employees/:employeeId","component":__pages_import_7__,"props":true},{"name":"agencies-agencyId-payouts-tinkoff-sd","path":"payouts/tinkoff-sd","component":__pages_import_8__,"props":true},{"name":"agencies-agencyId-tl-wizard","path":"tl/wizard","component":__pages_import_9__,"props":true}],"props":true}];

export default routes;